import React, { useState } from "react";
import { Heading, Button, Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Dropdown from "core/components/Dropdown/Dropdown";
import { useForm, Controller } from "react-hook-form";

import { useAvailableRegions } from "modules/regions/hooks/useAvailableRegions";
import TextWithIcon from "core/components/TextWithIcon/TextWithIcon";
import { useInspectedTemplate } from "../hook/useInspectedMessage";
import FullLoader from "core/components/Fullloader/FullLoader";
import TemplatesTable from "./TemplatesTable";
import { useSentinelTemplates } from "../helpers/useSentinelTemplates";

interface Region {
  region: string;
}
const InspectFormTemplate = () => {
  const { t } = useTranslation();
  const [option, setOption] = useState<string | undefined>();
  const [region, setRegion] = useState<string | undefined>();
  const regionsData = useAvailableRegions();
  const { data, isLoading } = useInspectedTemplate(region);
  const templates = useSentinelTemplates(data);

  if (!regionsData.data?.includes("ALL")) {
    regionsData.data?.unshift("ALL");
  }
  const regionsDataOptions = regionsData.data?.map((region) => {
    return { label: region, value: region };
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (option) {
      setRegion(option);
    } else {
      setRegion("ALL");
    }
  };
  const { control } = useForm<Region>();
  return (
    <Box>
      <Heading as="h2" size="md" mt="10px" textAlign="left">
        {t("supervision.tpl_heading")}
      </Heading>

      <form onSubmit={handleSubmit}>
        <Box display="flex" mt={5} mb={2}>
          <Controller
            control={control}
            name="region"
            render={({ onChange, value, ref }) => (
              <Dropdown
                searchable
                options={regionsDataOptions}
                name="region"
                inputRef={ref}
                value={
                  regionsDataOptions?.find((option) => option.value === value)!
                }
                onChange={(option) =>
                  onChange(option.value, setOption(option.value))
                }
                placeholder="Selectionner une region"
                noOptions={t("actions.test.form.no_options")}
              />
            )}
          />
          <Button type="submit" variant="secondary-solid" marginLeft={2}>
            {t("supervision.submit")}
          </Button>
        </Box>
      </form>
      <TextWithIcon icon="info">{t("supervision.tpl_text_info")}</TextWithIcon>
      {isLoading && <FullLoader />}
      {data ? (
        <TemplatesTable templates={templates} />
      ) : (
        <Flex mt={20} d="column">
          <Text m="20px" align="center">
            {t("supervision.tpl_placeholder")}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default InspectFormTemplate;
