import * as z from "zod";

export const routeSchema = z.enum([
  "home",
  "create",
  "template",
  "finalization",
  "target",
  "test",
  "administration",
  "manage-user",
  "create-user",
  "duplicate-user",
  "user",
  "profile",
  "manage-assets",
  "supervision",
  "meteo",
  "inspection",
  "metrics",
  "graphs",
]);

export type Route = z.infer<typeof routeSchema>;
