import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Flex, HStack, Image } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import { Dropdown } from "./Dropdown";

import LocaleSelect from "modules/i18n/components/LocaleSelect";
import LogoutIcon from "core/components/InterfaceIcons/LogoutIcon";
import { LinkItem } from "./LinkItem";
import { PATHS } from "modules/shared/constants/paths";
import { ProfileButton } from "./ProfileButton";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { useLogout } from "modules/authentication/hooks/useLogout";

const NavBar = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { mutate } = useLogout();

  const hasRightForManageUsers = useHasAccessRights(PATHS.USERS);
  const hasRightForManageAssets = useHasAccessRights(PATHS.CREATE_COLORSCHEME, [
    "POST",
  ]);
  const hasRightForManageImages = useHasAccessRights(PATHS.IMAGES, ["POST"]);
  const hasRightForQRCode = useHasAccessRights(PATHS.QRCODES, ["POST"]);
  const hasRightForGlobalMessages = useHasAccessRights(PATHS.GLOBAL_MESSAGES, [
    "GET",
  ]);

  const handleLogoutClick = useCallback(() => {
    mutate();
  }, [mutate]);

  const { t } = useTranslation();

  return (
    <Box data-testid="nav-bar" bg={isAuthenticated ? "dark" : ""}>
      <Container>
        <Flex
          px={4}
          justifyContent={isAuthenticated ? "space-between" : "flex-end"}
        >
          {isAuthenticated && (
            <HStack>
              <Image
                src={require("assets/Frame0_Logo.png").default}
                objectFit="contain"
              />
              <LinkItem target={PATHS.HOME} name={t("menu.templates")} />

              <Dropdown
                title={t("menu.admin")}
                menuLinks={[
                  {
                    path: PATHS.USERS,
                    label: t("menu.users"),
                    hasRight: hasRightForManageUsers,
                  },
                  {
                    path: PATHS.ASSETS,
                    label: t("menu.assets"),
                    hasRight:
                      hasRightForManageAssets ||
                      hasRightForManageImages ||
                      hasRightForQRCode,
                  },
                  {
                    path: PATHS.EMERGENCY_STOP,
                    label: t("menu.emergency_stop"),
                  },
                ]}
              />

              {hasRightForGlobalMessages && (
                <LinkItem
                  target={PATHS.GLOBAL_MESSAGES}
                  name={t("menu.global_message")}
                />
              )}
              <Dropdown
                title={t("menu.monitoring")}
                menuLinks={[
                  {
                    path: PATHS.MONITORING,
                    label: t(`menu.meteo`),
                  },
                  {
                    path: PATHS.INSPECTION,
                    label: t(`menu.inspection`),
                  },
                  {
                    path: PATHS.METRICS,
                    label: t(`menu.metrics`),
                  },
                  {
                    path: PATHS.GRAPHS,
                    label: t(`menu.graph`),
                  },
                ]}
              />
            </HStack>
          )}

          <HStack spacing={5}>
            <Box>{isAuthenticated && <LocaleSelect />}</Box>

            {isAuthenticated && (
              <ErrorBoundary fallbackRender={() => null}>
                <ProfileButton />
              </ErrorBoundary>
            )}

            {isAuthenticated && (
              <Button
                variant="unstyled"
                fontSize="xs"
                color="white"
                onClick={handleLogoutClick}
                data-testid="logout-button"
                rightIcon={<LogoutIcon color="white" />}
                h="30px"
              >
                {t("login.logout")}
              </Button>
            )}
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default NavBar;
